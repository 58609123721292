<template>
  <div class="page-content">
    <!-- 
    <div
      class="top-section"
      :style="{'background-image':'url('+imgLoadFlt('https://picture.liberty-resort.net/files/1625566078841-IMG_4427+.jpg')+')'}"
    ></div> -->
    <h3>This is switch section</h3>
    <CSwitch
      size="sm"
      class="swc sm-switch-l"
      color="info"
      variant="opposite"
      labelOn='ON'
      labelOff="しない"
    />
    <br>
    <br>
    <br>
    <CSwitch
      class="swc sw-2"
      color="info"
      variant="opposite"
      labelOn='ON'
      labelOff="非表示"
    />
  </div>
</template>

<script>
// import ArticleFacility from "@/components/ArticleFacility";

export default {
  name: "test",
  components: {
    // ArticleFacility,
  },
  data() {
    return {
      // ImgUrl: "https://picture.liberty-resort.net/files/",

      images: [
        {
          fileName: "1625566078841-IMG_4427+.jpg",
          hotel: "39222302010013282",
          category: 4,
          displayOrder: null,
          title: "ロビー",
          description: null,
          displayable: true,
        },
        {
          fileName: "1625566078841-IMG_4427+.jpg",
          hotel: "392223020100132844",
          category: 4,
          displayOrder: null,
          title: "ロビー",
          description: null,
          displayable: true,
        },
      ],
    };
  },

  methods: {
    kkt() {
      console.log(this);
    },
  },
  beforeMount() {},
};
</script>

<style lang="scss" >
.swc {
  font-weight: normal;
  .c-switch-slider {
    font-size: 7px !important;
    font-weight: normal !important;
  }
  .c-switch-slider::after {
    width: 70% !important;
    top: 35% !important;
    font-weight: normal !important;
    font-size: 4px !important;
  }
  .c-switch-input:checked ~ .c-switch-slider::before {
    transform: translateX(34px) !important;
  }
}
.sm-switch-l {
  width: 56px !important;
}
.sw-2 {
  width: 60px !important;
}
</style>